<template>
  <div class="pc-header-box">
    <div class="header">
      <div class="logo">
        <img class="logo-icon" :src="Logo" />
        <div>{{ $t("baseInfo.company") }}</div>
      </div>
      <div class="conntent">
        <el-menu :defaultActive="defaultActive" class="my-menu" mode="horizontal" @select="handleSelect"
          v-for="(item, index) in menuList" :key="index" router>
          <el-menu-item :index="item.key" :route="item.key">{{
            item.title
          }}</el-menu-item>
        </el-menu>
        <div class="go-nchor" @click="goAnchor('#contact-content')">
          {{ $t("baseInfo.contact") }}
        </div>
        <div class="language">
          <div class="ru language-item" @click.stop="checkLang('ru')" :class="{ 'active-lang': $i18n.locale === 'ru' }">
            Русский язык
          </div>
          <div class="uzb language-item" @click.stop="checkLang('uzb')"
            :class="{ 'active-lang': $i18n.locale === 'uzb' }">
            O`zbektili
          </div>
          <div class="en language-item" @click.stop="checkLang('en')" :class="{ 'active-lang': $i18n.locale === 'en' }">
            En
          </div>
          <div class="zh language-item" @click.stop="checkLang('zh')" :class="{ 'active-lang': $i18n.locale === 'zh' }">
            中文
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/images/logo.png";
export default {
  props: {},
  data() {
    return {
      Logo,
    };
  },

  computed: {
    defaultActive() {
      return this.$route.path;
    },
    menuList() {
      return this.$t("baseInfo.menu");
    },
  },
  created() {
    // this.auto();
  },
  // 生命周期 dom 渲染完成后
  mounted() {
    this.$i18n.locale = window.sessionStorage.getItem("lang"); // 获取缓存中的语言类型默认显示
  },

  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = key;
      // this.$router.push({
      //   name: keyPath,
      // });
    },
    // 点击切换时，默认为当前选择的语言
    checkLang(lang) {
      this.$i18n.locale = lang; // 设置给本地的i18n插件
      console.log(this.$i18n);
      // window.sessionStorage.setItem("lang", lang); // change国际化
    },
    //锚点跳转
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.pc-header-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: transparent;
  background: rgba(255, 255, 255, 0.75);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  z-index: 9999;

  .header {
    height: 80px;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 80px;
    z-index: 100;
    position: relative;
    padding: 0 15%;
    box-sizing: border-box;
    color: #333;
    display: flex;
    justify-content: space-between;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      box-sizing: border-box;
      height: 100%;
      padding: 10px 0;

      .logo-icon {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }

    .conntent {
      display: flex;

      .my-menu {
        height: 100%;
        background: rgba(255, 255, 255, 0);
        min-width: 100px;

        .el-menu-item {
          padding-top: 10px;
          height: 100%;
        }
      }

      .go-nchor {
        min-width: 100px;
        cursor: pointer;
        text-align: center;
        color: #909399;
      }

      .go-nchor:hover {
        color: #333;
        background: #fff;
      }

      .language {
        display: flex;
        align-items: center;
        padding-left: 10px;

        .language-item {
          position: relative;
          padding-right: 10px;
          /* 为分隔线留出空间 */
          cursor: pointer;
          color: #909399;
        }

        .active-lang {
          color: #333;
        }

        .language-item::after {
          content: "";
          position: absolute;
          background-color: #333;
          /* 边框颜色 */
          top: 39%;
          right: 3px;
          bottom: 0;
          width: 1px;
          height: 24%;
        }

        .language-item:last-child::after {
          display: none;
        }
      }
    }
  }
}
</style>