export default [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Mobile/home/Home.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/Mobile/product/product.vue'),
  },
  {
    path: '/product/introduce/:id',
    name: 'introduce',
    component: () => import('@/views/Mobile/product/introduce.vue'),
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/Mobile/service/service.vue')
  },
  {
    path: '/service/detail/:id',
    name: 'detail',
    component: () => import('@/views/Mobile/service/detail.vue'),
  },
  {
    path: '/intermediary',
    name: 'intermediary',
    component: () => import('@/views/Mobile/intermediary/intermediary.vue')
  },
]
