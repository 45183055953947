<template>
  <div class="pc-online-consultation" v-if="showClose" @click="connect">
    <a>
      <i class="el-icon-s-custom"></i>
      <br />
      <span>{{ name }}</span>
    </a>
    <div class="close-btn" @click="closeBtn">x</div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      showClose: true,
    };
  },

  computed: {
    name() {
      const current = this.$t(`baseInfo.onlineConsultation`);
      return current;
    },
  },
  created() { },
  mounted() { },
  methods: {
    closeBtn() {
      this.showClose = !this.showClose;
    },
    connect() {
      window.open("https://t.me/xianggyu", "_blank");
    }
  },
};
</script>

<style lang="less" scoped="scoped">
.pc-online-consultation {
  width: 66px;
  position: fixed;
  bottom: 200px;
  right: 25px;
  line-height: 0;
  z-index: 100;
  text-align: center;

  a {
    width: 66px;
    height: 54px;
    line-height: 24px;
    padding-top: 10px;
    opacity: 0.7;
    display: block;
    background-color: #1890ff;
    margin-bottom: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;

    i {
      font-size: 28px;
      color: #fff;
    }
  }

  a:hover {
    background-color: #017dc5;
  }

  .close-btn {
    content: "x";
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1864ab;
    color: white;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
  }
}
</style>