import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import i18n from './i18n/i18n'
import 'element-ui/lib/theme-chalk/index.css'
import "amfe-flexible"

import Vant from 'vant'
import 'vant/lib/index.css'
 
Vue.use(Vant)

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
