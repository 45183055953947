<template>
  <div class="contact-content" id="contact-content">
    <div class="contact-title">{{ contact }}</div>
    <div class="contact-tel">
      <div class="consultation-hotline">{{ consultationHotline }}：</div>
      <div class="tel">
        <div class="tel-item" v-for="(item, index) in telList" :key="index">
          {{ item }}
        </div>
      </div>
    </div>
    <div class="address-box">
      <div class="address">{{ address }}：</div>
      <div class="addressInfo">{{ addressInfo }}</div>
    </div>
  </div>
</template>
 
<script>
export default {
  props: {},
  data() {
    return {
       telList: ["+998991169988", "+998990491111"],
    };
  },

  computed: {
    contact() {
      return this.$t("baseInfo.contact");
    },
    consultationHotline() {
      return this.$t("baseInfo.consultation-hotline");
    },
    address() {
      return this.$t("baseInfo.address");
    },
    addressInfo() {
      return this.$t("baseInfo.addressInfo");
    },
  },
  async created() {},
  async mounted() {},
  watch: {},
  methods: {
    
  },
};
</script>
 
<style lang="less" scoped="scoped">
.contact-content {
  padding: 2% 7% 2%;
  background: #fff;
  .contact-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .contact-tel {
    display: flex;
    font-size: 16px;
    .consultation-hotline {
      font-size: 14px;
      min-width: 90px;
      padding: 3px 15px 0 0;
    }
    .tel {
      font-size: 14px;
      .tel-item {
        line-height: 1.8;
      }
    }
  }
  .address-box {
    font-size: 14px;
    display: flex;
    .address {
      font-size: 14px;
      min-width: 90px;
      padding-right: 15px;
    }
  }
}
</style>