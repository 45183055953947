import Vue from 'vue'
import VueRouter from 'vue-router'

import homeP from './PC/home'
import homeM from './Mobile/home'

Vue.use(VueRouter)

// 是否为手机端
const ISMOBILE = function () {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let flag = userAgent.match(
    /(phone|pad|pod|iPhone|ipad|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  if (!flag) {
    flag = window.screen.height > window.screen.width
  }
  return flag;
};

Vue.prototype.$IsMoble = ISMOBILE()
const routerPC = [
  {
    path: '/',
    redirect: '/home'
  },
  ...homeP,
  {
    path: '*',
    redire: '/'
  }
]

const routerM = [
  {
    path: '/',
    redirect: '/home'
  },
  ...homeM,
  {
    path: '*',
    redire: '/'
  }
]

const router = new VueRouter({
  routes: ISMOBILE() ? routerM : routerPC,
})
// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.matched.length) {
    // 如果目标路由有对应的页面组件，则滚动到顶部
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  next();
});

export default router