<template>
  <div id="app">
    <div class="home">
      <HeaderComponentMobile v-if="$IsMoble" />
      <HeaderComponentPC v-else />
      <OnlineConsultationPC />
      <!-- 主体内容 -->
      <router-view :key="$router.fullPath"></router-view>
      <BottomMessageMobile v-if="$IsMoble" />
      <BottomMessagePC v-else />
    </div>
  </div>
</template>

<script>
import HeaderComponentPC from "@/component/PC/header-component.vue";
import HeaderComponentMobile from "@/component/Mobile/header-component.vue";
import OnlineConsultationPC from "@/component/PC/online-consultation.vue";
import BottomMessagePC from "./component/PC/bottom-message.vue";
import BottomMessageMobile from "./component/Mobile/bottom-message.vue";
export default {
  name: "app",
  data() {
    return {
      data: {},
    };
  },
  components: {
    HeaderComponentPC,
    HeaderComponentMobile,
    OnlineConsultationPC,
    BottomMessagePC,
    BottomMessageMobile,
  },
  filters: {},
  computed: {},
  async created() {},
  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang == "en_US") {
      this.$i18n.locale = "en";
    } else if (lang == "ru-RU") {
      this.$i18n.locale = "ru";
    } else if (lang == "uzb") {
      this.$i18n.locale = "uzb";
    } else {
      this.$i18n.locale = "zh";
      this.locale = null;
    }
    this.$i18n.locale = "ru";
  },
  watch: {},
  methods: {},
};
</script>

<style lang="less">
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 16px;
  background-color: rgba(247, 247, 249, 1);
}
</style>

<style lang="less" scoped>
#app {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #333;
  background-color: rgba(247, 247, 249, 1);
  font-family: SourceHanSansCN, SourceHanSansCN;
}
</style>