<template>
  <div class="header-box">
    <div class="header">
      <div class="logo">
        <img class="logo-icon" :src="Logo" @click="jumpMenu('/'), false" />
        <div class="company-title">{{ $t("baseInfo.company") }}</div>
      </div>
      <div class="conntent">
        <div class="menu-btn">
          <img class="logo-icon" :src="LineBtn" @click="checkMenu" v-if="!showMenu" />
          <img class="logo-icon" :src="CloseBtn" @click="checkMenu" v-else />
        </div>
      </div>
    </div>
    <div class="menu-list" v-if="showMenu">
      <div class="menu-cell" v-for="(item, index) in menuList" :key="index" @click="jumpMenu(item.key), true">
        {{ item.title }}
      </div>
      <div class="menu-cell" @click="goAnchor('#contact-content')">
        {{ $t("baseInfo.contact") }}
      </div>
      <div class="language">
        <div class="ru language-item" @click.stop="checkLang('ru')" :class="{ 'active-lang': $i18n.locale === 'ru' }">
          Русский язык
        </div>
        <div class="uzb language-item" @click.stop="checkLang('uzb')"
          :class="{ 'active-lang': $i18n.locale === 'uzb' }">
          O`zbektili
        </div>
        <div class="en language-item" @click.stop="checkLang('en')" :class="{ 'active-lang': $i18n.locale === 'en' }">
          En
        </div>
        <div class="zh language-item" @click.stop="checkLang('zh')" :class="{ 'active-lang': $i18n.locale === 'zh' }">
          中文
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/images/logo.png";
import LineBtn from "@/assets/images/line-btn.svg";
import CloseBtn from "@/assets/images/close-btn.svg";
export default {
  props: {},
  data() {
    return {
      Logo,
      LineBtn,
      CloseBtn,
      showMenu: false,
    };
  },

  computed: {
    defaultActive() {
      return this.$route.path;
    },
    menuList() {
      return this.$t("baseInfo.menu");
    },
  },
  created() {
    // this.auto();
  },
  // 生命周期 dom 渲染完成后
  mounted() {
    this.$i18n.locale = window.sessionStorage.getItem("lang"); // 获取缓存中的语言类型默认显示
  },

  methods: {
    checkMenu() {
      this.showMenu = !this.showMenu;
    },
    jumpMenu(key) {
      this.$router.push({
        path: key,
      });

      this.showMenu = !this.showMenu;

    },
    // 点击切换时，默认为当前选择的语言
    checkLang(lang) {
      this.$i18n.locale = lang; // 设置给本地的i18n插件
      window.sessionStorage.setItem("lang", lang); // change国际化
    },
    //锚点跳转
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.header-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: transparent;
  background: rgba(255, 255, 255, 0.75);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  z-index: 9999;

  .header {
    height: 60px;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 60px;
    z-index: 100;
    position: relative;
    padding: 0 5%;
    box-sizing: border-box;
    color: #333;
    display: flex;
    justify-content: space-between;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 100%;
      padding: 5px 0;

      .logo-icon {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }

      .company-title {
        font-size: 20px;
      }
    }

    .conntent {
      display: flex;
    }
  }

  .menu-list {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background: transparent;
    background: rgba(255, 255, 255, 0.75);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    z-index: 9999;
    font-size: 15px;
    background: #f7f7f9;

    .menu-cell {
      padding: 10px 30px;
      border-bottom: 1px solid #007dc5;
    }

    .language {
      margin: 10px auto;
      display: flex;
      font-size: 14px;
      justify-content: center;

      .language-item {
        position: relative;
        padding-right: 10px;
        /* 为分隔线留出空间 */
        cursor: pointer;
        color: #909399;
      }

      .active-lang {
        color: #333;
      }

      .language-item::after {
        content: "";
        position: absolute;
        background-color: #333;
        /* 边框颜色 */
        top: 14%;
        right: 3px;
        bottom: 0;
        width: 1px;
        height: 78%;
      }

      .language-item:last-child::after {
        display: none;
      }
    }
  }
}
</style>